import React, { useState } from 'react';
import { Card } from '../Card';
import { useAppContext } from '../../contexts/AppContext';
import styled from '@emotion/styled';
import { Box, Button } from '@mui/material';
import { FEATURE_GRID_A_CONFIGURATION } from '../../types/static';

export interface CollectionCommonCProps {
  template: string;
  items: any;
}

const ProductSection = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridTemplateRows: '1fr 1fr',
  gap: theme.spacing(3),
  padding: 0,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}));

const ProductItem = styled(Card)(({ theme }) => ({
  gridColumn: '1 / span 2',
  gridRow: '1 / span 2',
}));

const MoreButton = styled('div')(({ theme }) => ({
  gridColumn: '1 / -1',
  padding: '10px',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  textAlign: 'center',
  marginTop: '10px',
  fontSize: theme.spacing(3),
}));

export const FeatureGridA = (props: CollectionCommonCProps) => {
  const { items = [], template } = props;
  const [showAll, setShowAll] = useState(false);
  const appContext = useAppContext();
  const { assetUrl } = appContext.useConfig();

  const visibleItems = showAll ? items : items.slice(0, 3);
  // const visibleItems = items.slice(0);

  return (
    <Box mb={5}>
      <ProductSection>
        {visibleItems.map((game: any, index: number) => {
          if (index === 0) {
            return (
              <ProductItem
                key={`${game.id}_${index}`}
                href={`/game/${game.slug}`}
                src={`${assetUrl}${
                  [1, 4, 7].includes(index)
                    ? game.images.cover16x9 || game.images.cover
                    : game.images.cover16x9 || game.images.cover
                }/`}
                description={game.description || ''}
                alt={game.name}
                title={game.name || undefined}
                sx={{ display: 'flex' }}
                template={template}
                width={700}
                height={550}
                layout="responsive"
                configuration={FEATURE_GRID_A_CONFIGURATION}
              />
            );
          } else {
            return (
              <Card
                key={`${game.id}_${index}`}
                href={`/game/${game.slug}`}
                src={`${assetUrl}${
                  [1, 4, 7].includes(index)
                    ? game.images.cover4x3 || game.images.cover
                    : game.images.cover16x9 || game.images.cover
                }/`}
                alt={game.name}
                sx={{ display: 'flex' }}
                template={template}
                width={335}
                height={315}
                layout="responsive"
                configuration={FEATURE_GRID_A_CONFIGURATION}
              />
            );
          }
        })}
      </ProductSection>
      {!showAll && items.length > 3 && (
        <MoreButton>
          <Button color="primary" variant="contained" onClick={() => setShowAll(true)}>More Games</Button>
        </MoreButton>
      )}
    </Box>
  );
};
